/* Provide sufficient contrast against white background */
a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.btn-primary {
  color: #fff;
  background-color: #1b6ec2;
  border-color: #1861ac;
}

.footer {
  padding-top: 10px;
  width: 100%;
  white-space: nowrap;
}

.member-summary th {
  width: 25%;
}

.member-data th {
  width: 25%;
}

.member-data td {
  width: 25%;
}

div h4 {
  margin-top: 60px;
}

.toolbar a.btn, .toolbar button.btn {
  margin-right: 4px;
}

.progress-bar {
  transition: none;
}